
import validator from 'validator';

const validateShareFreeBoard = (data) => {
    let errors = {};

    const {category_id,country_id} = data;
    if (validator.isEmpty(category_id)) {
        errors.category_id = '카테고리를 선택하세요.';
    }
    if (!country_id) {
        errors.country_id = '국가를 선택하세요.';
    }
    // if (validator.isEmpty(file)) {
    //     errors.file = 'please upload the file';
    // }
    return {
        isInvalid: Object.keys(errors).length > 0,
        error: errors,
    };
};

export default validateShareFreeBoard;